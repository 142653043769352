<template>
    <div>
        <card-header title="Share Links" icon="fa-share-square"/>

        <card-body>
            <card-list>
                <subheader-list-item title="Navigator App" icon="fa-compass"/>
                <container-list-item padded>
                    <b-field grouped>
                        <b-input :value="'https://app.tennisventures.net/#/share/' + user.shareToken + '/App'" expanded/>
                        <p class="control">
                            <b-button v-clipboard:copy="'https://app.tennisventures.net/#/share/' + user.shareToken + '/App'"><i class="fas fa-clipboard"></i></b-button>
                        </p>
                    </b-field>
                </container-list-item>
                <subheader-list-item title="Itinerary" icon="fa-calendar-alt"/>
                <container-list-item padded>
                    <b-field grouped>
                        <b-input :value="'https://app.tennisventures.net/#/share/' + user.shareToken + '/Itinerary'" expanded/>
                        <p class="control">
                            <b-button v-clipboard:copy="'https://app.tennisventures.net/#/share/' + user.shareToken + '/Itinerary'"><i class="fas fa-clipboard"></i></b-button>
                        </p>
                    </b-field>
                </container-list-item>
                <subheader-list-item title="Flights" icon="fa-plane"/>
                <container-list-item padded>
                    <b-field grouped>
                        <b-input :value="'https://app.tennisventures.net/#/share/' + user.shareToken + '/Flights'" expanded/>
                        <p class="control">
                            <b-button v-clipboard:copy="'https://app.tennisventures.net/#/share/' + user.shareToken + '/Flights'"><i class="fas fa-clipboard"></i></b-button>
                        </p>
                    </b-field>
                </container-list-item>
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import SeparatorListItem from "../../TIER/components/SeparatorListItem";
    import CardBody from "../../TIER/components/CardBody";
    import ContainerListItem from "../../TIER/components/ContainerListItem";

    import {client as http} from '../../http_client';

    export default {
        name: "Users",
        props: ['card', 'child', 'props'],
        components: {ContainerListItem, CardBody, SeparatorListItem, SubheaderListItem, CardList, CardHeader},
        data: function() {
            return {
                user: {
                    notes: [],
                    registrations: [],
                    documents: []
                }
            };
        },
        methods: {
            loadUser: function(force) {
                this.$emit('loading', true);
                http.get('/api/users/' + this.props.userId, {force}).then(response => {
                    this.user = response.data;
                    this.$emit('loading', false);
                });
            },
            reload: function() {
                this.loadUser(true);
            }
        },
        watch: {
            'props.userId': function() {
                this.loadUser(true);
            }
        },
        mounted() {
            this.loadUser();
        }
    };
</script>
